<template>
  <div ref="bDropdownSelect" class="account-popup popup">
    <div class="account-container">
      <div class="pills">
        <div
          v-if="hasAccountSettingsAccess() && extAdminHasAccounts()"
          class="mp-nav-brand-link"
          :class="[hasAccountSettingsAccess() && extAdminHasAccounts() ? '' : 'hide']"
          @click="handleAccountSettingsClick"
        >
          Account Settings
        </div>
        <p class="marginLeft39">
          <menu-icon :icon="['fal', 'sign-out']" title="Log Out" @click="onClickLogout" />
        </p>
      </div>

      <div v-if="showAccountList" class="mp-nav-div"></div>
      <div v-if="showAccountList" class="mp-account-label">
        <span class="act-swt-head">{{ header }}</span>
      </div>
      <div v-if="showAccountList" class="inp-wrap">
        <k-text-input
          :default-focus="true"
          :autofocus="true"
          placeholder="Start typing..."
          @keyup="onChange"
        />
        <font-awesome-icon :icon="['fal', 'chevron-right']" class="inp-icon" />
      </div>
      <div v-if="showAccountList && !solutionLoading" class="acct-drop-names top-margin">
        <div
          v-for="item in filteredOptions"
          :id="item.id"
          :key="item.id"
          class="account-item"
          @click="handleAdvDropdown(item.id)"
        >
          <span> {{ item.id === 73 ? 'Viamedia' : item.name }} </span>
          <font-awesome-icon
            v-if="item.advertisers.length"
            :icon="['far', 'chevron-down']"
            class="droparw"
            :class="item.id === activeAccountID || searchText.length >= 3 ? 'rotate-chevron' : ''"
          />
          <div
            v-if="
              (activeAccountID === item.id || searchText.length >= 3) && item.advertisers.length
            "
            class="acct-drop-names"
          >
            <div
              v-for="adv in filteredAdvertisers(item.advertisers)"
              :id="adv.id"
              :key="adv.id"
              :class="[
                'account-item',
                'sub-account-item',
                'no-border',
                { selected: isOptionSelected(item) },
              ]"
              @mouseover.stop="
                $set(optionHover, item.id, true);
                optionHovering = true;
              "
              @mouseleave="
                $set(optionHover, item.id, false);
                optionHovering = false;
              "
              @click="onClickOption(item, adv)"
            >
              {{ adv.name }}
            </div>
          </div>
        </div>
      </div>
      <b-loading-spinner v-if="solutionLoading" class="solution-loading-spinner" />
    </div>
  </div>
</template>
<script>
import commonHelper, { isBlank } from 'adready-api/helpers/common';
import { get, sync } from 'vuex-pathify';
import { clearSessionCookies } from 'adready-api/helpers/cookie-session-helper';
import axios from 'axios';
import { clearCacheFilters } from '@/helpers/global/url-helpers';
import EventBus from '@/adready-vue/helpers/global/event-bus';
import config from '~/config';
import authzMixin from '~/components/mixins/authz-mixin';
import {
  KEY_ACCOUNT_ID,
  KEY_ADVERTISER_ID,
  KEY_DEMO_ACCOUNT_ID,
  KEY_DEMO_ADVERTISER_ID,
  DEMO_ADVERTISERS,
  RANGE_CUSTOM,
  COMPARE_RANGE_PREV_DAY,
  VIA_MEDIA_CLIENT_NAME_FOR_API,
} from '~/constant';
import gaEventsMixin from '~/components/mixins/ga-events-mixin';
import { convertEpochToNYTimezone, isDemoInstance } from '~/util/utility-functions';
import forklift from '~/components/mixins/forklift-mixin';

export default {
  name: 'BDropdownSelect',

  components: {
    MenuIcon: () => import(/* webpackChunkName: "menu-icon" */ '../menu-icon.vue'),
    BLoadingSpinner: () =>
      import(/* webpackChunkName: "b-loading-spinner" */ './b-loading-spinner.vue'),
  },
  mixins: [authzMixin, gaEventsMixin, forklift],
  props: {
    options: {
      required: true,
      type: Array,
      default: () => [],
    },
    header: {
      required: false,
      type: String,
      default: () => 'Change Account',
    },
    hideAccountSwitchers: {
      required: true,
      type: Function,
      default: () => {},
    },
    showAccountList: {
      required: true,
      type: Boolean,
      default: () => true,
    },
  },
  data() {
    return {
      optionHovering: false,
      optionHover: {},
      searchText: '',
      activeAccountID: null,
      solutionLoading: false,
    };
  },
  computed: {
    account: sync('common/account'),
    currentAccountId: sync('common/currentAccountId'),
    switchingAccounts: sync('common/switchingAccounts'),
    allAccounts: get('common/accounts'),
    userAccounts: get('common/userAccounts'),
    advName: get('common/demoSelectedAdvertiserName'),
    dates: get('dashboard/dates'),
    filteredOptions() {
      const newOptions = [];

      if (isBlank(this.searchText)) {
        return newOptions.concat(this.options);
      }

      return newOptions.concat(this.options).filter((a) => {
        const hasAdvertisers = a.advertisers && a?.advertisers?.length;
        const accountFound = a?.name?.toLowerCase()?.includes(this?.searchText?.toLowerCase());
        if (accountFound) {
          if (hasAdvertisers) {
            a.advertisers.forEach((adv) => {
              adv.hide = false;
            });
          }
          return true;
        }

        if (hasAdvertisers) {
          a.advertisers.forEach((adv) => {
            adv.hide = !adv?.name?.toLowerCase()?.includes(this?.searchText?.toLowerCase());
          });

          const hasAdvertisersToShow = a.advertisers.some((adv) => !adv.hide);
          if (hasAdvertisersToShow) {
            return true;
          }
        }

        return false;
      });
    },
  },

  mounted() {
    setTimeout(() => {
      // Allow the current click event to complete before opening the switcher
      //
      // Previously we were using a @click.stop binding to avoid the
      // open-click to trigger a close-click via the outside click handler in
      // the switcher. While this works, it also stopped other popups from
      // closing correctly.
      window.addEventListener('click', this.onClickOutsideEvent);
      window.addEventListener('keydown', this.onClickOutsideEventKey);
    }, 0);
    if (isDemoInstance()) {
      if (
        this.allAccounts === undefined ||
        this.allAccounts === null ||
        this.allAccounts?.length === 0
      ) {
        this.solutionLoading = true;
        this.loadAccounts(true).then(() => {
          this.solutionLoading = false;
        });
      }
    }
  },

  beforeDestroy() {
    window.removeEventListener('click', this.onClickOutsideEvent);
    window.removeEventListener('keydown', this.onClickOutsideEventKey);
  },

  methods: {
    fireAdvertiserSelectionChangeGAEvent(option) {
      if (config.gaEnabled()) {
        option = option || { accountId: 0, id: 0, name: 'unknown', organizationId: 0 };
        const gaEv = {
          acc_id: option.accountId,
          adv_id: option.id,
          adv_name: option.name,
          adv_org_id: option.organizationId,
        };
        this.fireGAEvent('app_advt_change', gaEv);
      }
    },
    hasAccountSettingsAccess() {
      return this.isInternalAdmin || this.isExternalAdmin;
    },
    extAdminHasAccounts() {
      if (this.isExternalAdmin) {
        return this.userAccounts.length > 0;
      }
      return true;
    },
    handleAccountSettingsClick() {
      EventBus.$emit('on-account-settings');
    },
    filteredAdvertisers(advertisers) {
      const filteredList = advertisers.filter((adv) => !adv.hide);
      return commonHelper.caseInsensitiveSort([].concat(filteredList), 'name');
    },
    switchAccount(account) {
      if (account && (isDemoInstance() || account?.id !== this.currentAccountId)) {
        // if different, set it as current and clear out the IO state
        this.switchingAccounts = true;
        // set null to avoid cyclic ref error when resetting state via vuex-reset (next line)
        if (account.id === 73) {
          account.name = VIA_MEDIA_CLIENT_NAME_FOR_API;
        }
        this.account = account;
        this.currentAccountId = this.account.id;
        localStorage.setItem(KEY_ACCOUNT_ID, this.currentAccountId);
        localStorage.removeItem(KEY_ADVERTISER_ID);
        sessionStorage.clear();
      } else if (account && account?.id === this.currentAccountId) {
        localStorage.setItem(KEY_ACCOUNT_ID, this.currentAccountId);
      }
    },
    async onClickLogout() {
      this.fireLogoutGAEvent();
      const redirectTo = config.APP_URL;
      this.$store.dispatch('common/setFilterCacheId', null);
      // clearing cookies to avoid maintaining filter after login
      sessionStorage.clear();
      clearSessionCookies();
      // clearCacheFilters();
      await axios.delete(`${config.ADREADY_URL}/api/token/remove`, { withCredentials: true });

      window.location = redirectTo;
    },
    handleAdvDropdown(id) {
      if (this.activeAccountID === id) {
        this.activeAccountID = null;
        return;
      }
      this.activeAccountID = id;
    },
    isOptionSelected(option) {
      return (
        (!this.optionHovering && option?.id === this.currentOptionId) ||
        this.optionHover[option?.id]
      );
    },
    isSameAdvertiser(adv1, adv2) {
      return adv1 === adv2;
    },
    isSameAdvertiserUsingId(adv1, adv2) {
      return adv1 === adv2;
    },

    // Choose new option to switch to
    onClickOption(acc, adver) {
      const isSameAdv = isDemoInstance()
        ? this.isSameAdvertiser(this.advName, adver?.name)
        : this.isSameAdvertiserUsingId(this?.advertiser?.id, adver?.id);
      if (isSameAdv) {
        this.close();
        return;
      }
      sessionStorage.clear();
      clearCacheFilters('loggedUserId');
      this.$store.dispatch('dashboard/resetAdvanceFilters');
      // reset hide flag to false for all advertisers
      this.options.map((a) => {
        const hasAdvertisers = a.advertisers && a.advertisers.length;
        if (hasAdvertisers) {
          a.advertisers.map((adv) => {
            adv.hide = false;
            return adv;
          });
        }
        return a;
      });
      if (isDemoInstance()) {
        const keys = Object.keys(DEMO_ADVERTISERS).map((key) => parseInt(key, 10));
        const demoAdvertiser = keys.includes(adver.id)
          ? DEMO_ADVERTISERS[adver.id]
          : DEMO_ADVERTISERS[0];
        const eDate = new Date();

        const updatedDates = {
          ...this.dates,
          startDate: convertEpochToNYTimezone(new Date(demoAdvertiser.startDate)),
          endDate: demoAdvertiser.endDate
            ? convertEpochToNYTimezone(new Date(demoAdvertiser.endDate))
            : convertEpochToNYTimezone(new Date()),
          compareStartDate: convertEpochToNYTimezone(new Date(demoAdvertiser.compareStartDate)),
          compareEndDate: demoAdvertiser.compareEndDate
            ? convertEpochToNYTimezone(new Date(demoAdvertiser.compareEndDate))
            : convertEpochToNYTimezone(eDate.setDate(eDate.getDate() - 1)),
          dateRangeOption: RANGE_CUSTOM,
          dateCompareOption: COMPARE_RANGE_PREV_DAY,
        };
        this.$store.set('dashboard/dates', updatedDates);
        const accountRef = this.allAccounts.find(
          (o) => o.id === parseInt(demoAdvertiser.accountId, 10)
        );
        if (accountRef) {
          const accountDemo = JSON.parse(JSON.stringify(accountRef));
          const { advertisers } = accountDemo;
          const advertiserDemo = JSON.parse(
            JSON.stringify(
              !keys.includes(adver.id)
                ? advertisers[0]
                : advertisers.filter((adv) => adv.id === demoAdvertiser.advertiserId)[0]
            )
          );

          localStorage.setItem(KEY_DEMO_ACCOUNT_ID, acc.id);
          localStorage.setItem(KEY_DEMO_ADVERTISER_ID, adver.id);
          this.$store.set('common/demoSelectedAdvertiserName', adver.name);

          // For whitelabelEnabled
          const {
            logoFile,
            appConsoleLogoFile,
            appConsoleSquareLogoFile,
            whitelabelEnabled,
            theme,
          } = acc.organization;
          accountDemo.organization.logoFile = JSON.parse(JSON.stringify(logoFile));
          accountDemo.organization.appConsoleLogoFile = JSON.parse(
            JSON.stringify(appConsoleLogoFile)
          );
          accountDemo.organization.appConsoleSquareLogoFile = JSON.parse(
            JSON.stringify(appConsoleSquareLogoFile)
          );
          accountDemo.organization.whitelabelEnabled = whitelabelEnabled;
          accountDemo.organization.theme = JSON.parse(JSON.stringify(theme));

          this.fireAdvertiserSelectionChangeGAEvent(advertiserDemo);
          this.switchAccount(accountDemo);
          localStorage.setItem(KEY_ADVERTISER_ID, advertiserDemo.id);
          this.$emit('selected-option', advertiserDemo, adver);
        } else {
          this.fireAdvertiserSelectionChangeGAEvent(adver);
          this.switchAccount(acc);
          localStorage.setItem(KEY_ADVERTISER_ID, adver.id);
          this.$emit('selected-option', adver);
        }
      } else {
        this.fireAdvertiserSelectionChangeGAEvent(adver);
        this.switchAccount(acc);
        localStorage.setItem(KEY_ADVERTISER_ID, adver.id);
        this.$emit('selected-option', adver);
      }
      this.close();
    },
    fireLogoutGAEvent() {
      if (config.gaEnabled()) {
        const gaEv = {};
        this.fireGAEvent('drsd_auth_logout', gaEv, false);
      }
    },
    close() {
      this.optionHover = {};
      this.optionHovering = false;
      this.$emit('close');
    },

    // events
    onClickOutsideEvent(event) {
      const el = this.$refs.bDropdownSelect;
      if (el && !(el === event.target || el.contains(event.target))) {
        this.close();
      }
    },

    onClickOutsideEventKey(event) {
      if (event.key === 'Escape') {
        if (!(this.$el === event.target || this.$el.contains(event.target))) {
          this.close();
        }
      }
    },

    onChange(val, e) {
      this.searchText = val;
      if (e.key && e.key.includes('Enter')) {
        if (this.filteredOptions.length === 1) {
          const { advertisers = [] } = this.filteredOptions[0];
          if (advertisers.length > 0) {
            const reqAdv = advertisers.filter((adv) =>
              adv?.name?.toLowerCase()?.includes(val?.toLowerCase())
            );
            if (reqAdv.length > 0) {
              this.onClickOption(this.filteredOptions[0], reqAdv[0]);
            }
          }
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.no-border {
  border: unset !important;
}
.mp-nav-div {
  height: 13px;
  margin-bottom: 10px;
  border-bottom: 1px solid #4c5466;
}
::v-deep .menu-dropdown-item-icon {
  display: inline-block;
  float: right;
  padding: 0 !important;
  font-size: 13px !important;
  & svg {
    color: #d2dbf1;
    &:hover {
      color: #ffffff;
    }
  }
}

.mp-nav-brand-link {
  display: inline-block;
  padding: 2px 8px;
  font-size: 13px;
  color: #d2dbf1;
  cursor: pointer;
  border: 1px solid #4c5466;
  border-radius: 4px;
  &:not(:first-child) {
    margin-left: 8px;
    position: absolute;
    right: 70px;
  }

  &:hover {
    color: #fff;
    border: 1px solid #fff;
  }
  &.hide {
    visibility: hidden;
  }
}

.droparw {
  float: right;
  transition: all 0.3s ease;
  &.rotate-chevron {
    transform: rotate(180deg);
  }
}
.inp-wrap {
  position: relative;
  .inp-icon {
    position: absolute;
    top: 1px;
    right: 0;
    box-sizing: border-box;
    width: 40px;
    height: 33px;
    padding: 0.8rem;
    color: var(--overtext);
    background-color: var(--primarycolor);
    border-radius: 0px 3px 3px 0px;
  }
}
.act-swt-head {
  margin-bottom: 3px;
  font-size: 13px;
  font-weight: 400;
  color: white;
  text-align: left;
}
.account-popup {
  position: absolute;
  top: 50px;
  right: 172px;
  z-index: 9;
  box-sizing: border-box;
  width: 280px;
  padding: 15px;
  padding: 20px 20px 20px 20px;
  background: $white;
  border: 3px solid #f1f1f1;
  border-radius: 3px;
  box-shadow: 0px 2px 20px 2px rgb(0 0 0 / 50%);
  .account-item {
    width: 100%;
    // height: 30px;
    padding: 6px 0px;
    margin: 0;
    font-size: 13px;
    color: rgba(129, 140, 165, 1);
    text-align: left;
    cursor: pointer;
    border-bottom: 1px solid rgba(255, 255, 255, 0.05);

    &:hover {
      color: white;
    }

    .acct-drop-names {
      max-height: unset !important;
    }
    svg {
      width: 0.5em !important;
      font-size: 18px !important;
    }
  }
  .sub-account-item {
    padding: 4px 10px !important;
    line-height: 16px;
    color: #626c83 !important;
    border-bottom: 0px !important;
    // &:last-child {
    //   margin-bottom: 0 !important;
    // }
    &:hover {
      color: #ffffff !important;
    }
  }
  .fa-icon-table {
    margin-left: 240px;
    cursor: pointer;
  }
  .account-container {
    width: 240px !important;
    max-height: 300px;
    background-color: $white;
    .pills {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      min-width: 240px;
      height: 25px;
    }
    .acct-drop-names {
      max-height: 230px;
      padding-right: 2px;
      padding-left: 1px;
      margin-top: 0;
      overflow: scroll;
      line-height: inherit !important;
    }
    .top-margin {
      margin-top: 10px !important;
    }
    .form-input-field {
      margin-top: 0 !important;
      color: #2c3035 !important;
    }
    .mp-account-label {
      padding-bottom: 0;
      text-align: left;
    }
    ::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      color: rgb(117, 117, 117);
    }
  }
}
::v-deep .form-input label {
  left: 30px;
}
::v-deep .form-input label {
  display: none !important;
}
::v-deep .form-input .form-input-field {
  font-size: 13px !important;
  color: #2c3035 !important;
}
.marginLeft39 {
  margin-left: 39px;
  right: 20px;
  position: absolute;
}
</style>
